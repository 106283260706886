






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions() {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý dữ liệu Chốt RD",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Thời gian (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              value: {
                type: "integer",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (>=)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Số tiền (<=)",
                    },
                  },
                },
              },
              adsAgencyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Agencies",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencies", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              adsAgencyAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản quảng cáo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencyAccounts", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT User",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              description: {
                attrs: {
                  label: "Mô tả",
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            value: {
              text: "Số tiền",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            adsAgency: {
              text: "TKCN",
              options: {
                label: true,
                subProp: "adsAgency.name",
                sortBy: "adsAgencyId",
              },
            },
            adsAgencyAccount: {
              text: "TKQC",
              options: {
                label: true,
                subProp: "adsAgencyAccount.name",
                sortBy: "adsAgencyAccountId",
              },
            },
            mktUser: {
              text: "MKT User",
              options: {
                label: true,
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            productName: {
              text: "Tên sản phẩm",
            },
            description: {
              text: "Mô tả",
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("adsRdCosts", "import", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const lines = [
                                `Cập nhật thành công: ${result.updatedIndexes.length}`,
                                "",
                                `Các hàng bị lỗi ${result.errorIndexes.join(",")}`,
                              ];
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("adsRdCosts", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "description",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsRdCosts", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("adsRdCosts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsRdCosts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsRdCosts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              productName: {
                attrs: {
                  label: "Tên sản phẩm",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian",
                },
              },
              value: {
                type: "integer",
                attrs: {
                  label: "Số tiền",
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              adsAgencyAccountId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Tài khoản quảng cáo",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsAgencyAccounts", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["time", "value", "mktUserId", "adsAgencyAccountId", "productName", "description"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["time", "value", "mktUserId", "adsAgencyAccountId", "productName", "description"],
              },
            },
            delete: {
              confirmDisplayField: "description",
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
